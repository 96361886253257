import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import H2 from "../components/H2";
import NotImage from "./404.gif";

const Wrapper = styled.div`
  text-align: center;
`;

function NotFound() {
  return (
    <Wrapper>
      <H2>Sorry, page was not found.</H2>
      <h3>
        Got to{" "}
        <Link to="/" style={{ textDecoration: "none", fontSize: "19px" }}>
          Home
        </Link>
      </h3>

      <div>
        <img src={NotImage} alt="" />
      </div>
    </Wrapper>
  );
}

export default NotFound;
